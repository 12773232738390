import {USER_STATUSES_MAP} from '@/constants'
import ModelInterface from '@/models/ModelInterface'
import {RawUser, UserAttrItem, UserMsgrs, UserOptions} from '@/types/RawUser'
import {DateTime} from 'luxon'


export default class User extends ModelInterface<RawUser> {

  public getCtText (): string {
    return DateTime.fromMillis(this.data._ct).toFormat('dd.MM.yyyy HH:mm')
  }

  public getTsText (): string {
    return DateTime.fromMillis(this.data._ts).toFormat('dd.MM.yyyy HH:mm')
  }

  public setAttrs (attrs: UserAttrItem[]) {
    this.data.attrs = attrs || []
  }

  public getAttrBySlug (slug: string): UserAttrItem | undefined {
    return this.attrs.find(attr => attr.slug === slug)
  }

  public getAttrById (id: string): UserAttrItem | undefined {
    return this.attrs.find(attr => attr.id === id)
  }

  get firstName (): string {
    return this.data.firstName || ''
  }
  get lastName (): string {
    return this.data.lastName || ''
  }
  get middleName (): string {
    return this.data.middleName || ''
  }
  get phone (): string {
    return this.data.phone || ''
  }

  get status (): string {
    return this.data.status
  }

  get statusText (): string {
    return USER_STATUSES_MAP[this.status].name || ''
  }

  get msgrs (): UserMsgrs {
    return this.data.msgrs
  }

  get fullName (): string {
    let fullName = ''
    fullName += this.lastName ? this.lastName + ' ' : ''
    fullName += this.firstName ? this.firstName + ' ' : ''
    fullName += this.middleName ? this.middleName + ' ' : ''
    return fullName;
  }

  get attrs (): UserAttrItem[] {
    return this.data.attrs || []
  }

  set attrs (attrs: UserAttrItem[]) {
    this.data.attrs = attrs || []
  }

  get options (): UserOptions {
    return this.data.options
  }

  set options (options: UserOptions) {
    this.data.options = options
  }

  
}